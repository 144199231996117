import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vueEsign from 'vue-esign'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import '@/permission' // permission control
import Vant from 'vant';
import global_variable from '@/utils/global_variable'  // 全局图片路径
Vue.prototype.GLOBAL = global_variable
import 'vant/lib/index.css';
import animated from 'animate.css' // npm install animate.css --save安装，在引入
import 'lib-flexible/flexible'
//全局路由跳转函数
// this.$router.go(-1);//返回上一层
//用法 @click="goRouter('Registerlogin')"
Vue.prototype.goRouter = function (path) {
    if (path === '-1') {
        this.$router.go(-1);
    } else {
        this.$router.push({
            path: path
        });
    }
};

Vue.use(Vant,animated);
Vue.use(vueEsign)
Vue.use(ElementUI)
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
