import router from "./router";
import store from "./store";
import { Toast } from 'vant';
import {getItem,setItem,removeItem} from "@/utils/storage";

router.beforeEach(async (to, from, next) => {
    // setItem('url',to.fullPath)
    if (to.meta.title) {
        document.title = to.meta.title
      }
    if(to.path === '/Webpage' || to.path === '/login' ||to.path === '/weWacetOath'){//登录界面放行
        next();
    } else{
        next();
        // if(store.state.infoArr.display == 0 && to.meta.display){
        //     Toast.fail('你没有资格进入招商系统')
        //     next({path: "/"});
        //     return
        // } else if(store.state.infoArr.display == 1){
        //     next();
        // }
        const hasGetUserInfo = store.state.name;//这里每次判断store里面的name
        if(hasGetUserInfo){
            next();
        } else{
            store.dispatch("info");
            next();//注意如果使用addRoutes()要改为next({ ...to, replace: true });否则无限循环错误
        }
    }
    
});