import Vue from 'vue'
import Vuex from 'vuex'
import {login,userinfo} from "@/request/api"; // 导入api接口
import {getItem,setItem,removeItem} from "@/utils/storage";
import { Toast } from 'vant';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token:getItem('token'),
    name:'',
    infoArr:getItem('infoArr'),
    authentication:false,//认证状态
    display:getItem('display'),
    urle:'',
    activeBottom:''
  },
  mutations: {
    //username
    __name(state,name){
      state.name = name
    },
    __login(state, token) {
      state.token = token;
      // 为了防止页面刷新数据丢失，我们还需要把数据放到本地存储中，这里仅仅是为了持久化数据
      setItem("token", state.token);
    },
    __user(state,data){
      state.infoArr = data;
      // console.log(state.infoArr)
      setItem("infoArr", state.infoArr);
    },
    __wxOat(state,data){
      state.token = data;
      setItem("token", state.token);
    },
    __display(state,data){
      state.display = data;
      setItem("display", state.display);
    },
    __urle(state,data){
      state.urle = data;
    },
    __bottomTab(state,data){
      state.activeBottom = data;
    }
  },
  actions: {
     // 登录
        login(context, state) {
          return new Promise((resolve, reject) => {
            login(state).then(res=>{
              if (res.code != 1000) {
                return;
              }
              context.commit("__login", res.data.token);
                resolve();
            }).catch(error=>{
              reject(error);
            })
          });
        },
        //微信登录
        wxOat(context, state) {
          return new Promise((resolve, reject) => {
            console.log(state)
            context.commit("__wxOat", state.token);
            resolve();
          });
        },
        // 获取用户信息
        info(context, state) {
          return new Promise((resolve, reject) => {
            if(state){
              context.commit("__login", state);
            } else{
              userinfo().then(res => {
                context.commit("__user", res.data);
                context.commit("__name", res.data.role_name);
                context.commit("__display", res.data.display);
              }).catch(error => {
                // reject(error)
              })
            }
            
          });
        },
  },
  modules: {
  }
})
