// 在http.js中引入axios
import axios from 'axios'; // 引入axios
import QS from 'qs'; // 引入qs模块，用来序列化post类型的数据，后面会提到
import router from '@/router'
import{setCode} from '@/utils/fununi'
import {getItem,setItem,removeItem} from "@/utils/storage";
import {
    Toast
} from 'vant';
// 环境的切换
if (process.env.NODE_ENV == 'development') {
    //开发环境
    axios.defaults.baseURL = '/api';
} else if (process.env.NODE_ENV == 'debug') {
    console.log('1')
    axios.defaults.baseURL = '';
} else if (process.env.NODE_ENV == 'production') {
    //生产环境
    axios.defaults.baseURL = '';
}

axios.defaults.timeout = 10000;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';


// 先导入vuex,因为我们要使用到里面的状态对象
// vuex的路径根据自己的路径去写
import store from '@/store/index';
// 请求拦截器
axios.interceptors.request.use(
    config => {
        // console.log('这里是请求拦截器')
        // 每次发送请求之前判断vuex中是否存在token
        // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
        const token = store.state.token;
        token && (config.headers.Authorization = token);
        return config;
    },
    error => {
        return Promise.error(error);
    }
)
// http响应拦截器
// 响应拦截器
axios.interceptors.response.use(
    response => {
        if(response.status == 204){//微信登录失败，重新登陆
            let href = window.location.href;
            var url = href.substring(0, href.length - 2); //vue自动在末尾加了 #/ 符号，截取去掉
            var jingPosit = url.indexOf("com/") + 4; //获取域名结束的位置
            var urlLeft = url.substring(0, jingPosit);//url左侧部分
            let urlp = setCode('state')
            console.log(urlp)
            if(urlp && urlp != 'null'){
              window.location = urlLeft + "#" + urlp;//拼接跳转
              removeItem('url')
              return
            }
              window.location = urlLeft + "#/";//拼接跳转
        }
        const res = response.data;
        // 如果返回的状态码为1000，说明接口请求成功，可以正常拿到数据
        // 否则的话抛出错误
        if (res.code != 1000) {
            if(res.code == 1005){
                return res
            }
            if (res.code == 5003) {
                // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
                // router.beforeEach(async (to, from, next) => {
                //     console.log(to)
                //     next();
                // });
                router.replace({
                    path: '/WeWacetOath',
                    // path:'/login',
                    // query: { redirect: router.currentRoute.fullPath }
                })
                
            }
            return res;
        } else {
            return res;
        }
    },
    // 服务器状态码不是2开头的的情况
    // 这里可以跟你们的后台开发人员协商好统一的错误状态码
    // 然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
    // 下面列举几个常见的操作，其他需求可自行扩展
    error => {
        Toast.fail("服务器异常");
        // store.commit('__loginup')
        return Promise.reject(error)
        // return error;

    }
);
export default axios;
