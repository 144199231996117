<template>
  <div id="app">
    <template v-if="network">
      <div v-if="footShow">
        <van-tabbar v-model="active" active-color="#000A33FF">
          <!-- 未开发暂时隐藏 -->
          <van-tabbar-item to="/">
            万维云
            <template #icon="props">
              <img :src="props.active ? icon.active : icon.inactive" />
            </template>
          </van-tabbar-item>
          <van-tabbar-item to="/CompanyIntroduction">
            公司介绍
            <template #icon="props">
              <img :src="props.active ? icon.active1 : icon.inactive1" />
            </template>
          </van-tabbar-item>
          <van-tabbar-item to="/User">
            个人
            <template #icon="props">
              <img :src="props.active ? icon.active2 : icon.inactive2" />
            </template>
          </van-tabbar-item>

          <template v-if="display == 1">
            <van-tabbar-item to="/Investment">
              招商
              <template #icon="props">
                <img :src="props.active ? icon.active3 : icon.inactive3" />
              </template>
            </van-tabbar-item>
          </template>
        </van-tabbar>
        <van-number-keyboard safe-area-inset-bottom />
      </div>
      <keep-alive>
        <router-view v-if="Iscache" />
      </keep-alive>
      <router-view v-if="!Iscache" />
    </template>
    <template v-if="!network">
      <van-empty image="network" description="未连接到网络,请检查您的网络" />
    </template>
  </div>
</template>

<script>
import FoontEr from "./components/footer/foonter.vue";
import { mapState } from "vuex";
export default {
  components: {
    FoontEr,
  },
  data() {
    return {
      footShow: true,
      Iscache: "",
      network: true, //默认有网
      active: 0,
      icon: {
        active: require("@/assets/img/indexCur.png"),
        inactive: require("@/assets/img/index.png"),
        active1: require("@/assets/img/IntroduceCur.png"),
        inactive1: require("@/assets/img/Introduce.png"),
        active2: require("@/assets/img/userCur.png"),
        inactive2: require("@/assets/img/user.png"),
        active3: require("@/assets/img/attractCur.png"),
        inactive3: require("@/assets/img/attract.png"),
      },
    };
  },
  computed: {
    ...mapState(["attract", "infoArr", "display"]),
  },
  watch: {
    // 监控van底部跳转页面
    $route(to) {
      // 处理在当前页面刷新active不在当前页面问题
      const thisPage = to.name;
      if (thisPage == "Index") {
        this.active = 0;
      } else if (thisPage == "CompanyIntroduction") {
        this.active = 1;
      } else if (thisPage == "User") {
        this.active = 2;
      } else if (thisPage == "Investment") {
        this.active = 3;
      }

      // const thisPage = to.name;
      if (to.meta.Iscache == true) {
        this.Iscache = true;
      } else {
        this.Iscache = false;
      }
      if (to.meta.buttonShow == true) {
        this.footShow = true;
      } else {
        this.footShow = false;
      }
    },
  },
  mounted() {
    // 检测断网
    window.addEventListener("offline", () => {
      this.network = false;
    });
    window.addEventListener("online", () => {
      this.network = true;
    });
  },
};
</script>

<style>
@import "assets/icon/iconfont.css";

/*字体图标*/
@font-face {
  font-family: "iconfont";
  src: url("/assets/icon/iconfont.eot");
  src: url("/assets/icon/iconfont.eot?#iefix") format("embedded-opentype"),
    url("/assets/icon/iconfont.woff2") format("woff2"),
    url("/assets/icon/iconfont.woff") format("woff"),
    url("/assets/icon/iconfont.ttf") format("truetype"),
    url("/assets/icon/iconfont.svg#iconfont") format("svg");
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body,
div,
p,
ul,
ol,
li,
dl,
dt,
dd,
table,
tr,
td,
form,
hr,
h1,
h2,
h3,
h4,
h5,
h6,
fieldset,
img,
input {
  margin: 0;
  padding: 0;
  font-family: "PingFang SC";
}
body {
  display: flex;
  flex-direction: column;
}
img {
  display: block;
}
a {
  margin: 0;
  text-decoration: none;
}
li,
ol {
  list-style: none;
}
.swiperBox {
  width: 100%;
  position: fixed;
  left: 50%;
  top: 41%;
  z-index: 101;
  transform: translate(-50%, -50%);
}
.swiper-container {
  touch-action: none;
}
.popupE {
  width: 100%;
  height: 100vh;
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.8);
}
.boeWidth img {
  margin: auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.boepoa img {
  width: 240px;
  height: 272px;
}
.texter {
  font-size: 14px;
  color: #fff;
  padding: 10px 45px;
}
.marBottom {
  width: 240px;
  margin: auto;
  background: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
}
.riherwm {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 60px;
  height: 60px;
}
.boeprac {
  padding: 20px 10px;
}
.titlea {
  padding-bottom: 3px;
}
.imgaer {
  width: 60px !important;
  height: 60px !important;
}
.boeprac .titlea {
  font-size: 12px;
  font-weight: bold;
}
.boeprac .titletext {
  font-size: 10px;
  color: #000a33ff;
}
.icondace {
  color: #fff;
  border: 1px solid #fff;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  bottom: -50px;
  transform: translate(-50%, 0);
  font-size: 20px;
}
</style>
