import Vue from 'vue'
import VueRouter from 'vue-router'
// import Index from '../views/Index.vue'
import store from '@/store/index'
Vue.use(VueRouter)
//定义了meta元数据里的index是1表示是导航页面 2是子页面 isLogin 为true表示该页面需要登录反之buttonShow为true为显示底部，false隐藏,Iscache该页面需要缓存
const routes = [
    {
        path: '/',
        name: 'Index',
        component: () => import('../views/Index.vue'),
        meta: { index: 1, isLogin: true, buttonShow: true,title:'万维云',display:false }
    },
    {
        path: '/investment',
        name: 'Investment',
        component: () => import('../views/Investment/Investment.vue'),
        meta: { index: 1, isLogin: true, buttonShow: true,title:'招商',display:true }
    },
    
    {
        path: '/userincome',
        name: 'Userincome',
        component: () => import('../views/Userincome.vue'),
        meta: { index: 2, isLogin: true, buttonShow: false,title:'我的收入',Iscache:true,display:true }
    },
    {
        path: '/myCustomer',
        name: 'MyCustomer',
        component: () => import('../views/MyCustomer/MyCustomer.vue'),
        meta: { index: 2, isLogin: true, buttonShow: false,title:'我的客户',Iscache:true,display:true }
    },
    {
        path: '/myTeam',
        name: 'MyTeam',
        component: () => import('../views/MyTeam/MyTeam.vue'),
        meta: { index: 2, isLogin: true, buttonShow: false,title:'我的团队',Iscache:true,display:true }
    },
    {
        path: '/joinoperation',
        name: 'JoinOperation',
        component: () => import('../views/MyCustomer/JoinOperation.vue'),
        meta: { index: 2, isLogin: true, buttonShow: false,title:'加入我的团队',display:false  }
    },
    {
        path: '/operation',
        name: 'Operation',
        component: () => import('../views/MyTeam/Operation.vue'),
        meta: { index: 2, isLogin: true, buttonShow: false,title:'团队操作',display:false }
    },
    {
        path: '/mySetUp',
        name: 'MySetUp',
        component: () => import('../views/MySetUp.vue'),
        meta: { index: 1, isLogin: true, buttonShow: false,title:'个人设置',display:false }
    },
    {
        path: '/bank',
        name: 'Bank',
        component: () => import('../views/Bank.vue'),
        meta: { index: 2, isLogin: true, buttonShow: false,title:'绑定银行卡',display:false }
    },
    {
        path: '/friends',
        name: 'Friends',
        component: () => import('../views/Friends.vue'),
        meta: { index: 2, isLogin: true, buttonShow: false,title:'发圈素材',display:false }
    },
    {
        path: '/companyIntroduction',
        name: 'CompanyIntroduction',
        component: () => import('../views/CompanyIntroduction/CompanyIntroduction.vue'),
        meta: { index: 2, isLogin: true, buttonShow: true,title:'公司介绍' ,display:false}
    },
    {
        path: '/user',
        name: 'User',
        component: () => import('../views/User/User.vue'),
        meta: { index: 2, isLogin: true, buttonShow: true,title:'个人',display:false }
    },
    {
        path: '/cashOut',
        name: 'CashOut',
        component: () => import('../views/CashOut.vue'),
        meta: { index: 2, isLogin: true, buttonShow: false,title:'提现',display:true }
    },
    {
        path: '/withdrawalparticulars',
        name: 'Withdrawalparticulars',
        component: () => import('../views/Withdrawalparticulars.vue'),
        meta: { index: 2, isLogin: true, buttonShow: false,title:'提现详情',display:false }
    },
    {
        path: '/addCity',
        name: 'AddCity',
        component: () => import('../views/promotion/AddCity.vue'),
        meta: { index: 2, isLogin: true, buttonShow: false,title:'选择城市',display:false }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login/Login.vue'),
        meta: { index: 2, isLogin: false, buttonShow: false,title:'登录',display:false }
    },
    {
        path: '/webpage',
        name: 'Webpage',
        component: () => import('../views/Login/Webpage.vue'),
        meta: { index: 2, isLogin: false, buttonShow: false,title:'密码登录',display:false }
    },
    {
        path: '/weWacetOath',
        name: 'WeWacetOath',
        component: () => import('../views/Login/WeWacetOath.vue'),
        meta: { index: 2, isLogin: false, buttonShow: false,title:'微信登录',Iscache:true,display:false }
    },
    // end招商投资人开始
    {
        path: '/investor',
        name: 'Investor',
        component: () => import('../views/Investor/Investor.vue'),
        meta: { index: 2, isLogin: true, buttonShow: false,title:'加盟城市',display:false}
    },
    {
        path: '/contract',
        name: 'Contract',
        component: () => import('../views/Investor/Contract.vue'),
        meta: { index: 2, isLogin: true, buttonShow: false,title:'合同',display:false}
    },
    {
        path: '/filLinformation',
        name: 'FilLinformation',
        component: () => import('../views/Investor/FilLinformation.vue'),
        meta: { index: 2, isLogin: true, buttonShow: false,title:'填写签约运营中心信息',display:false}
    },
    {
        path: '/faceRecognition',
        name: 'FaceRecognition',
        component: () => import('../views/Investor/FaceRecognition.vue'),
        meta: { index: 2, isLogin: true, buttonShow: false,title:'人脸识别',display:false}
    },
    {
        path: '/toPay',
        name: 'ToPay',
        component: () => import('../views/Investor/ToPay.vue'),
        meta: { index: 2, isLogin: true, buttonShow: false,title:'运营中心签约信息',display:false}
    },
    {
        path: '/voucher',
        name: 'Voucher',
        component: () => import('../views/Investor/Voucher.vue'),
        meta: { index: 2, isLogin: true, buttonShow: false,title:'上传凭证',display:false}
    },
    {
        path: '/settledinformation',
        name: 'Settledinformation',
        component: () => import('../views/InvestorSettled/Settledinformation.vue'),
        meta: { index: 2, isLogin: true, buttonShow: false,title:'填写运营入驻信息表',display:false}
    },
    {
        path: '/SettledinformationTex',
        name: 'SettledinformationTex',
        component: () => import('../views/InvestorSettled/SettledinformationTex.vue'),
        meta: { index: 2, isLogin: true, buttonShow: false,title:'填写运营入驻信息表',display:false}
    },
    {
        path: '/typePage',
        name: 'TypePage',
        component: () => import('../views/InvestorSettled/TypePage.vue'),
        meta: { index: 2, isLogin: true, buttonShow: false,title:'待审核',display:false}
    },
    {
        path: '/order',
        name: 'Order',
        component: () => import('../views/InvestorSettled/Order.vue'),
        meta: { index: 2, isLogin: true, buttonShow: false,title:'签约订单',display:false}
    },
    {
        path: '/success',
        name: 'Success',
        component: () => import('../views/InvestorSettled/Success.vue'),
        meta: { index: 2, isLogin: true, buttonShow: false,title:'订单签约成功',display:false}
    },
    {
        path: '/logistics',
        name: 'Logistics',
        component: () => import('../views/InvestorSettled/Logistics.vue'),
        meta: { index: 2, isLogin: true, buttonShow: false,title:'查看物流',display:false}
    },
    // 运营商流程
    {
        path: '/contractOper',
        name: 'ContractOper',
        component: () => import('../views/InvestorOperator/ContractOper.vue'),
        meta: { index: 2, isLogin: true, buttonShow: false,title:'运营商合同',display:false}
    },
    {
        path: '/filLinformationOper',
        name: 'FilLinformationOper',
        component: () => import('../views/InvestorOperator/FilLinformationOper.vue'),
        meta: { index: 2, isLogin: true, buttonShow: false,title:'运营商签约中心',display:false}
    },
    {
        path: '/toPayOper',
        name: 'ToPayOper',
        component: () => import('../views/InvestorOperator/ToPayOper.vue'),
        meta: { index: 2, isLogin: true, buttonShow: false,title:'运营商支付',display:false}
    },
    {
        path: '/voucherOper',
        name: 'VoucherOper',
        component: () => import('../views/InvestorOperator/VoucherOper.vue'),
        meta: { index: 2, isLogin: true, buttonShow: false,title:'运营商凭证',display:false}
    },
    {
        path: '/companyIntroductionTrain',
        name: 'CompanyIntroductionTrain',
        component: () => import('../views/CompanyIntroduction/CompanyIntroductionTrain.vue'),
        meta: { index: 2, isLogin: true, buttonShow: false,title:'招商培训',display:false}
    },
    {
        path: '/settledinformationOper',
        name: 'SettledinformationOper',
        component: () => import('../views/InvestorOperator/SettledinformationOper.vue'),
        meta: { index: 2, isLogin: true, buttonShow: false,title:'填写运营入驻信息表',display:false}
    }
    
]

const router = new VueRouter({
    routes,
    // mode:'history'
    scrollBehavior (to, from, savedPosition) {//解决每次跳转页面保证在顶部
        return { x: 0, y: 0 }
      }
})
import {getItem,setItem,removeItem} from "@/utils/storage";

// 路由守卫
// to: 要访问的页面路由信息
// from: 来自哪个页面的路由信息
// next：放行的标记
router.beforeEach((to, from, next) => {
    if (to.matched.some(res => res.meta.isLogin)) { // 判断是否需要登录
        if (store.state.token) { // 如果已经登陆就放行
            next()
        } else {
            setItem('url',to.fullPath)
            // 前往登录页面
            next({path: "/WeWacetOath" });
        }

    } else {
        next()
    }
});
export default router
