/**
 * api接口统一管理
 */
 import request from "@/request/http";
//登录
 export function login(data) {
    return request({
        url: "/other/Index/login",
        method: "post",
        data
    });
}
//获取用户信息
export function userinfo(token) {
    return request({
        url: "/user/index/userInfo",
        method: "GET",
        params: { token }
    });
}

//code换取微信用户信息
export function getWebCode(data) {
    return request({
        url: "/other/Wechat/getWebCode",
        method: "POST",
        data
    });
}
//发送手机验证码
export function sendSms(data) {
    return request({
        url: "/other/settings/sendSms",
        method: "POST",
        data
    });
}
//客户团队
export function customersLists(data) {
    return request({
        url: "/user/team/customersLists",
        method: "POST",
        data
    });
}
//绑定微信
export function bindWechat(data) {
    return request({
        url: "/other/Index/bindWechat",
        method: "POST",
        data
    });
}
//会员操作
export function operate(data) {
    return request({
        url: "/user/team/operate",
        method: "POST",
        data
    });
}

//获取会员信息
export function getMemberInfo(data) {
    return request({
        url: "/user/team/getMemberInfo",
        method: "POST",
        data
    });
}

//银行卡列表
export function listscard(data) {
    return request({
        url: "/user/Bank_Card/lists",
        method: "POST",
        data
    });
}
//会员团队//我的团队
export function memberLists(data) {
    return request({
        url: "/user/team/memberLists",
        method: "POST",
        data
    });
}
//添加银行卡
export function banckAdd(data) {
    return request({
        url: "/user/Bank_Card/add",
        method: "POST",
        data
    });
}
//获取银行编号
export function getBankCode(data) {
    return request({
        url: "/user/Bank_Card/getBankCode",
        method: "POST",
        data
    });
}
//删除银行卡
export function bancDelete(data) {
    return request({
        url: "/user/Bank_Card/del",
        method: "POST",
        data
    });
}
//获取银行卡信息
export function getBankCardInfo(data) {
    return request({
        url: "/user/Bank_Card/getBankCardInfo",
        method: "POST",
        data
    });
}

//设置默认银行卡
export function setDefaultBankCard(data) {
    return request({
        url: "/user/Bank_Card/setDefaultBankCard",
        method: "POST",
        data
    });
}

//实名验证
export function realNameVerification(data) {
    return request({
        url: "/user/index/realNameVerification",
        method: "POST",
        data
    });
}
//收入明细
export function incomeDetail(data) {
    return request({
        url: "/user/Financial/incomeDetail",
        method: "POST",
        data
    });
}
//提现
export function withdrawal(data) {
    return request({
        url: "/user/Financial/withdrawal",
        method: "POST",
        data
    });
}
//提现详情
export function withdrawalDetail(data) {
    return request({
        url: "/user/Financial/withdrawalDetail",
        method: "POST",
        data
    });
}
//撤销提现
export function cancelWithdrawal(data) {
    return request({
        url: "/user/Financial/cancelWithdrawal",
        method: "POST",
        data
    });
}
//提现记录
export function withdrawalsRecord(data) {
    return request({
        url: "/user/Financial/withdrawalsRecord",
        method: "POST",
        data
    });
}
//提现验证
export function verifyWithdrawal(data) {
    return request({
        url: "/user/Financial/verifyWithdrawal",
        method: "POST",
        data
    });
}
// 投资人api

//获取省份
export function getProvince(data) {
    return request({
        url: "/other/index/getProvince",
        method: "POST",
        data
    });
}
//获取城市
export function getCity(data) {
    return request({
        url: "/other/index/getCity",
        method: "POST",
        data
    });
}
//获取区域
export function getArea(data) {
    return request({
        url: "/other/index/getArea",
        method: "POST",
        data
    });
}
//上传图片
export function uploadImage(data) {
    return request({
        url: "/other/Settings/uploadImage",
        method: "POST",
        data
    });
}
//公司信息
export function wwyInfo(data) {
    return request({
        url: "/other/index/wwyInfo",
        method: "POST",
        data
    });
}
//入驻运营中心
export function settleInOpc(data) {
    return request({
        url: "/investors/index/settleInOpc",
        method: "POST",
        data
    });
}
//获取入驻运营的订单信息
export function getOrdersInfo(data) {
    return request({
        url: "/investors/orders/getOrdersInfo",
        method: "POST",
        data
    });
}
//去付款
export function payment(data) {
    return request({
        url: "/investors/orders/payment",
        method: "POST",
        data
    });
}
//入驻运营的订单列表
export function SettledList(data) {
    return request({
        url: "/investors/orders/lists",
        method: "POST",
        data
    });
}
//入驻运营商
export function settleInOper(data) {
    return request({
        url: "/investors/index/settleInOpm",
        method: "POST",
        data
    });
}
//填写运营入驻信息
export function generateContract(data) {
    return request({
        url: "/investors/index/generateContract",
        method: "POST",
        data
    });
}
