<template>
  <div>
    
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props:{
    activeBottom:{

    }
  },
  data() {
    return {
      icon: {
        active: require("@/assets/img/indexCur.png"),
        inactive: require("@/assets/img/index.png"),
        active1: require("@/assets/img/IntroduceCur.png"),
        inactive1: require("@/assets/img/Introduce.png"),
        active2: require("@/assets/img/userCur.png"),
        inactive2: require("@/assets/img/user.png"),
        active3: require("@/assets/img/attractCur.png"),
        inactive3: require("@/assets/img/attract.png"),
      },
    };
  },
     computed: {
    ...mapState(["attract","infoArr","display"]),
  },
  methods: {
  },
};
</script>

<style scoped>
.swiperBox {
  width: 100%;
  position: fixed;
  left: 50%;
  top: 41%;
  z-index: 101;
  transform: translate(-50%, -50%);
}
.swiper-container {
  touch-action: none;
}
.popupE {
  width: 100%;
  height: 100vh;
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.8);
}
.boeWidth img {
  margin: auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.boepoa img {
  width: 240px;
  height: 272px;
}
.texter {
  font-size: 14px;
  color: #fff;
  padding: 10px 45px;
}
.marBottom {
  width: 240px;
  margin: auto;
  background: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
}
.riherwm {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 60px;
  height: 60px;
}
.boeprac {
  padding: 20px 10px;
}
.titlea {
  padding-bottom: 3px;
}
.imgaer {
  width: 60px !important;
  height: 60px !important;
}
.boeprac .titlea {
  font-size: 12px;
  font-weight: bold;
}
.boeprac .titletext {
  font-size: 10px;
  color: #000a33ff;
}
.icondace {
  color: #fff;
  border: 1px solid #fff;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  bottom: -50px;
  transform: translate(-50%, 0);
  font-size: 20px;
}
</style>